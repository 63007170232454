import React, { useEffect, useState } from "react";
import { Row, Container, Col, Nav, Navbar } from "react-bootstrap";
import { Offcanvas, Modal, Dropdown, Button } from "react-bootstrap";
import MenuIcon1 from "../assets/images/icons/menuicon.png";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import Logo from "../assets/images/logo.png";
import GamesIcon from "../assets/svg/GamesIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon12 from "../assets/images/games-icon/Exchange.svg";

import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsIcon from "../assets/images/games-icon/slots.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";

import BotHeadIcon1 from "../assets/images/games-icon/In-play.png";
import BotHeadIcon2 from "../assets/images/games-icon/Cricket.png";
import BotHeadIcon3 from "../assets/images/games-icon/Soccer.png";
import BotHeadIcon4 from "../assets/images/games-icon/Aviator.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon-tiger1.png";
import BotHeadIcon6 from "../assets/images/games-icon/Teen-patti.png";
import BotHeadIcon7 from "../assets/images/games-icon/roulette.png";
import BotHeadIcon10 from "../assets/images/games-icon/Tennis.png";
import BotHeadIcon11 from "../assets/images/games-icon/slots.png";

import BonusImg from "../assets/images/bonus-icon.png";
import refferal from "../assets/images/referral-icon.png";
import CasinoIcon from "../assets/images/games-icon/Casino.svg";
import KingmakerIcon from "../assets/images/icons/king.png";

const HeaderAfterLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { user, wallet, balance, exposureAmount } = useSelector(
    (state) => state.auth
  );
  const { betMatches, userBets } = useSelector((state) => state.sports);
  // const betMatches = [
  //   {
  //     exposureAmount: 6438.5,
  //     matchName: "Australia v West Indies",
  //     refMatchId: 10,
  //     totalBets: 2,
  //   },
  // ];
  // const userBets = [
  //   {
  //     odds: 130,
  //     stack: 50,
  //     selectionType: "back",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:13.000Z",
  //   },
  //   {
  //     odds: 1.23,
  //     stack: 50,
  //     selectionType: "lay",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:04.000Z",
  //   },
  // ];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);

  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);
  // function handelSubmit() {
  //   return <Index />;
  // }
  const [showToggle, setShowToggle] = useState(true);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;
  useEffect(() => {
    if (showLeftbarToggle) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  return (
    <>
      <header className="header aftrlgn">
        <div className="top_head">
          <Container fluid>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="leftbar_toggle d-md-none d-block">
                      <Button
                        variant="primary"
                        onClick={() => setShowLeftbarToggle((s) => !s)}
                      >
                        <img src={MenuIcon1} />
                      </Button>
                    </div>
                    <div className="logo">
                      <a href="/home">
                        <img src={appDetails?.LOGO_URL} alt="Logo" />
                        {/* <img src={Logo} alt="Logo" /> */}
                      </a>
                    </div>
                  </div>

                  <Navbar expand="xl" className="d-none">
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      onClick={() => setShowToggle((s) => !s)}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav>
                        <Nav.Link href="/home" className="logoImg d-xl-none">
                          <img src={appDetails?.LOGO_URL} alt="Logo" />
                        </Nav.Link>
                        <Nav.Link href="/home">
                          <img src={HomeIcon1} /> Home
                        </Nav.Link>
                        <Nav.Link
                          //href="/sportsbook/cricket"
                          href="/exchange"
                          className="exchange-nav"
                        >
                          <img src={SportsIcon1} /> Exchange
                        </Nav.Link>
                        {/* <Nav.Link href="/sports">
                          <img src={Exchange} alt="Exchange Icon" width={20} /> Exchange
                        </Nav.Link> */}
                        <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                          <img src={EzugiIcon} /> Ezugi
                        </Nav.Link>
                        <Nav.Link href="/casino/evolution">
                          <img src={CashierIcon1} /> Evolution
                        </Nav.Link>
                        <Nav.Link href="/Slots">
                          <img src={SlotsIcon} /> Slots
                        </Nav.Link>
                        {/* <Nav.Link href={CasinoProviders["worldcasino"]?.href}>
                          <GamesIcon />
                          World Casino
                        </Nav.Link> */}
                        {/* <Nav.Link href="/games-all">
                          <GamesIcon /> Casino
                        </Nav.Link> */}
                        <Nav.Link href="/cashier">
                          <img src={CashierIcon1} /> Cashier
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>

                  <div className="headerRight">
                    {/* <a href="/deposit" className="deposit primary">
                      Deposit
                    </a> */}
                    <Dropdown align="end" className="me-2">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="tel:{user?.mobileno}">
                          <b>{user?.mobileno}</b>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <h6>Balance</h6>
                          <span>
                            INR {numberWithCommas(wallet?.balance || 0)}
                          </span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          href="#"
                          onClick={() => setShowBetModal(true)}
                        >
                          <h6>Exposure</h6>
                          <span>INR {numberWithCommas(exposureAmount || 0)}</span>
                        </Dropdown.Item> */}
                        <Dropdown.Item href="#">
                          <h6>Cash</h6>
                          <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <h6>Bonus</h6>
                          <span>INR {numberWithCommas(wallet?.coins)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/gatewaylist" className="deposit">
                          Deposit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <div className="funds me-2">
                      <ul>
                        {/* <li>
                        <div onClick={() => setShowBetModal(true)}>
                          <div>
                            Exp:{" "}
                            <span>{numberWithCommas(exposureAmount || 0)}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <a href="#">
                          Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                        </a>
                      </li> */}
                        <li className="">
                          <a href="/gatewaylist" className="deposit">
                            Deposit
                          </a>
                        </li>
                      </ul>
                    </div>
                    <Button variant="primary menu-btn" onClick={handleShow}>
                      <ProfileIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="bottom_head ">
          <Container fluid>
            <Row>
              <Col xs={12} className="">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Inplay", {
                          state: { selectedTab: "1" },
                        });
                      }}
                    >
                      <img src={SportsIcon12} alt="bottom head link icon" />
                      <span>Sports</span>
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Cricket", {
                          state: { selectedTab: "3" },
                        });
                      }}
                    >
                      <img src={BotHeadIcon2} alt="bottom head link icon" />
                      <span>Cricket</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Soccer", {
                          state: { selectedTab: "3" },
                        });
                      }}
                    >
                      <img src={BotHeadIcon3} alt="bottom head link icon" />
                      <span>Soccer</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Tennis", {
                          state: { selectedTab: "3" },
                        });
                      }}
                    >
                      <img src={BotHeadIcon10} alt="bottom head link icon" />
                      <span>Tennis</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/allCasino", { state: { selectedTab: "1" } });
                      }}
                    >
                      <img src={CasinoIcon} alt="bottom head link icon" />
                      <span>All Casino</span>
                    </a>
                  </li>
                  <li>
                    <a href="/Slots">
                      <img src={BotHeadIcon11} alt="bottom head link icon" />
                      <span>Slots</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a href="/kingmaker">
                      <img src={KingmakerIcon} alt="bottom head link icon" />
                      <span>Kingmaker</span>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/casino/ezugi/DragonTiger1">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>DragonTiger</span>
                  </a>{" "}
                </li>
                <li>
                  <a href="/casino/ezugi/ItalianRoulette">
                    <img src={BotHeadIcon7} alt="bottom head link icon" />
                    <span>Roulette</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/TeenPatti01">
                    <img src={BotHeadIcon6} alt="bottom head link icon" />
                    <span>TeenPatti</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/sportsbook", { state: { selectedTab: "3" } });
                    }}
                  >
                    <img src={BotHeadIcon3} alt="bottom head link icon" />
                    <span>Soccer</span>
                  </a>
                </li> */}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <h6>{user?.mobileno}</h6>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              <div className="col-6">
                <div className="balance mb-3">Bonus</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3">
                  {numberWithCommas(wallet?.coins) || 0}
                </div>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="primary  w-100">Withdraw</Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
              {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <ProfileIcon />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <BetIcon />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus" className="img_size">
                  <img src={BonusImg} />
                  <span className="bonus-space">Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral" className="img_size">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <StatementIcon />
                  <span>Account Statement</span>
                </a>
              </li>
              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li onClick={() => dispatch(logoutUser())}>
                <a href="#">
                  <LogoutIcon />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a target="_blank" href={`${appDetails?.TELEGRAM}`}>
                  <TelegramIcon />
                </a>
              </li>
              {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
              <li className="theme-btn">
                <button
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </button>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
