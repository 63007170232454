import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../assets/images/logo.png";
import SportsIcon from "../assets/images/games-icon/Exchange.svg";
import BotHeadIcon1 from "../assets/images/games-icon/In-play.svg";
import CasinoIcon from "../assets/images/games-icon/Casino.svg";
import BotHeadIcon2 from "../assets/images/games-icon/Cricket.svg";
import BotHeadIcon3 from "../assets/images/games-icon/Soccer.svg";
import Tennis from "../assets/images/games-icon/Tennis.svg";
import HorseRacing from "../assets/images/games-icon/HorseRacing.svg";
import XPG from "../assets/images/games-icon/XG.svg";
import livetv from "../assets/images/games-icon/livetv-icon.png";
import BotHeadIcon4 from "../assets/images/games-icon/Aviator.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon-tiger1.png";
import BotHeadIcon6 from "../assets/images/games-icon/Teen-patti.png";
import BotHeadIcon7 from "../assets/images/games-icon/roulette.png";
import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import SlotsnIcon from "../assets/images/games-icon/slots.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";
import KingmakerImg from "../assets/images/icons/king.png";
import { useSelector } from "react-redux";
import BotHeadIcon10 from "../assets/images/games-icon/Tennis.png";
import BotHeadIcon11 from "../assets/images/games-icon/slots.png";
import login from "../assets/images/icons/login.svg";
import signup from "../assets/images/icons/sign.png";
// import BotHeadIcon4 from "../assets/images/games-icon/Aviator.png";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <header className="header beforeheader">
        <div className="top_head">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                  <div className="middle-part d-none">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link href="/">
                              {/* <HomeIcon /> */}
                              <img src={HomeIcon1} />
                              Home
                            </Nav.Link>
                            <Nav.Link
                              href="/sportsbook"
                              className="exchange-nav"
                            >
                              <div>
                                {/* <SportsIcon /> */}
                                <img src={SportsIcon1} />
                              </div>
                              Exchange
                            </Nav.Link>

                            <Nav.Link href="/sign-in">
                              {/* <GamesIcon />  */}
                              <img src={EzugiIcon} />
                              Ezugi
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <GamesIcon />  */}
                              <img src={EzugiIcon} />
                              Evolution
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              {/* <GamesIcon />  */}
                              <img src={SlotsnIcon} />
                              Slots
                            </Nav.Link>
                            {/* <Nav.Link href={CasinoProviders["worldcasino"]?.href}>
                      <GamesIcon />
                      World Casino
                    </Nav.Link> */}
                            {/* <Nav.Link href="/games-all">
                        <GamesIcon /> Casino
                      </Nav.Link> */}
                            <Nav.Link href="/sign-in">
                              {/* <CashierIcon /> */}
                              <img src={CashierIcon1} />
                              Cashier
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>
                  <div className="headerRight ">
                    <ul className="social d-none">
                      <li>
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <TelegramIcon />
                        </a>
                      </li>
                    </ul>
                    <div className="login-signup-btn">
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate("/sign-in");
                        }}
                        className="button-primary btn_signin"
                      >
                        <img
                          src={login}
                          alt="login"
                          className="d-block d-md-none"
                        />
                        Login
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          navigate("/mobile-number");
                        }}
                        className="button-primary btn_signup ms-2"
                      >
                        <img
                          src={signup}
                          alt="login"
                          className="d-block d-md-none"
                        />
                        Signup
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Inplay", {
                          state: { selectedTab: "3" },
                        });
                      }}
                    >
                      <img src={SportsIcon} alt="bottom head link icon" />
                      <span>Sports</span>
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Cricket", {
                          state: { selectedTab: "3" },
                        });
                      }}
                    >
                      <img src={BotHeadIcon2} alt="bottom head link icon" />
                      <span>Cricket</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Soccer", {
                          state: { selectedTab: "3" },
                        });
                      }}
                    >
                      <img src={BotHeadIcon3} alt="bottom head link icon" />
                      <span>Soccer</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/sports/Tennis", {
                          state: { selectedTab: "3" },
                        });
                      }}
                    >
                      <img src={BotHeadIcon10} alt="bottom head link icon" />
                      <span>Tennis</span>
                    </a>
                  </li>
                  <li>
                    <a href="/mobile-number">
                      <img src={CasinoIcon} alt="bottom head link icon" />
                      <span>All Casino</span>
                    </a>
                  </li>
                  <li>
                    <a href="/Slots">
                      <img src={BotHeadIcon11} alt="bottom head link icon" />
                      <span>Slots</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={KingmakerImg} alt="bottom head link icon" />
                      <span>Kingmaker</span>
                    </a>
                  </li>
                  {/* <li>
              <a href="/mobile-number">
                <img src={BotHeadIcon2} alt="bottom head link icon" />
                <span>Cricket</span>
              </a>
            </li>
            <li>
              <a href="/mobile-number">
                <img src={BotHeadIcon3} alt="bottom head link icon" />
                <span>Soccer</span>
              </a>
            </li>
            <li>
              <a href="/mobile-number">
                <img src={Tennis} alt="bottom head link icon" />
                <span>Tennis</span>
              </a>
            </li> */}
                  {/* <li>
              <a href="/mobile-number">
                <img src={HorseRacing} alt="bottom head link icon" />
                <span>Horse Racing</span>
              </a>
            </li>
            <li>
              <a href="/mobile-number">
                <img src={XPG} alt="bottom head link icon" />
                <span>XPG</span>
              </a>
            </li>
            <li>
              <a href="/mobile-number">
                <img src={livetv} alt="bottom head link icon" />
                <span>Live TV</span>
              </a>
            </li> */}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
