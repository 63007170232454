import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";
import LeftBarSports from "../../../components/LeftBarSports";

import Aviator from "../../../../assets/images/spribe/aviator.jpg";
import Dice from "../../../../assets/images/spribe/Dice.jpg";
import Plinko from "../../../../assets/images/spribe/Plinko.jpg";
import Goal from "../../../../assets/images/spribe/Goal.jpg";
import Hilo from "../../../../assets/images/spribe/Hilo.jpg";
import Mines from "../../../../assets/images/spribe/Mines.jpg";
import Keno from "../../../../assets/images/spribe/Keno.jpg";
import MiniRoulette from "../../../../assets/images/spribe/MiniRoulette.jpg";
import Hotline from "../../../../assets/images/spribe/Hotline.jpg";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";

const Spribe = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="d-xl-none">
          <LeftBarSports />
        </div>

        <div className="container">
          <div className="slots-section">
            <div className="games-section">
              <div className="heading my-4">
                <h4>Spribe</h4>
                <div className="back-link" onClick={() => navigate(-1)}>
                  Back <BackIcon />
                </div>
              </div>

              <ul>
                <li>
                  <a href="/casino/spribe/aviator">
                    <img src={Aviator} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/dice">
                    <img src={Dice} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/plinko">
                    <img src={Plinko} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/goal">
                    <img src={Goal} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/hilo">
                    <img src={Hilo} alt="Live casino game" />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/mines">
                    <img src={Mines} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/keno">
                    <img src={Keno} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/miniroulette">
                    <img src={MiniRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/casino/spribe/hotline">
                    <img src={Hotline} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Spribe;
