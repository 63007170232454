import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

export class BeforeTerms extends PureComponent {
  render() {
    return (
      <>
        <Header />

        <main className="main">
          <div className="container">
            <h2 class="sectionTitle my-4"> Terms & Conditions</h2>
            <div className="terms-content">
              <p>
                The following Betting Rules pertaining to the bookmaker
                Armaniexchange (here in after referred to as the Rules)
                stipulate the manner of accepting bets, paying winnings and
                resolving disputes, as well as the specific features of certain
                bets on certain sports. These Rules shall govern any other
                relations between the bookmaker Armaniexchange and the customer.
              </p>
              <p>
                These Rules shall apply to betting on the website and at
                Armaniexchange betting facilities.
              </p>
              <p>
                1. Bet is a risk-driven agreement for potential winnings entered
                into between the customer and the bookmaker under the
                established Rules, where the fulfillment of such agreement is
                conditioned by an event whose outcome is yet to be determined.
                Bets are accepted on the conditions offered by the bookmaker.
              </p>
              <p>
                2. Outcome is the result of the event (events) on which the bet
                was placed.
              </p>
              <p>
                3. Customer is an individual placing a bet with the bookmaker on
                an outcome.
              </p>
              <p>
                4. Bet Cancellation is an outcome on which the bet is not
                settled and winnings are not paid. As per the Rules, in the
                event of “bet cancellation”, an arrangement between the
                bookmaker and the customer shall be deemed unconcluded and the
                stake shall be refunded.
              </p>
              <p>
                5. Regular Time is the duration of the match subject to the
                regulations of the relevant sport, including time added by the
                referee. Regular time does not include extra time, overtime(s),
                penalty shootouts, etc.
              </p>
            </div>
          </div>
        </main>

        <BeforeLoginFooter />
      </>
    );
  }
}

export default BeforeTerms;
